
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'TextInput',
	components: {},
	emits: ['onBlur', 'onChange', 'update:modelValue'],
})
export default class TextInput extends Vue {
	@Prop() label!: string;
	@Prop() modelValue!: string | number;
	@Prop({ default: 'This field is required' }) errorMsg!: string;
	@Prop() showError!: boolean;
	@Prop({ default: 'text' }) type!: 'text' | 'email';
	@Prop({ default: false }) altStyle!: boolean;
	@Prop({ default: '' }) id!: string;

	get elementName() {
		return `${this.label.toLowerCase().split(' ').join('-')}-input`;
	}
}
