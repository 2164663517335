
import { ConsentFormEntity } from '@/common/types/ConsentFormQuestions.type';
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';
import WaiverQuestion from '@/modules/consent/components/waiver-question.component.vue';

@Options({
	name: 'WaiverForms',
	components: { WaiverQuestion },
})
export default class WaiverForms extends Vue {
	forms: ConsentFormEntity[] = [];
	currentForm = 0;
	currentQuestion = 0;
	isLoading = false;

	// automated flow for development to skip to end of waivers
	skipWaivers = process.env.VUE_APP_DEV_MODE
		? () => {
				for (let i = 0; i < this.forms.length; i++) {
					for (let j = 0; j < this.forms[i].ConsentFormQuestions.length; j++) {
						this.onWaiverAgree(this.forms[i].ConsentFormQuestions[j].ConsentQuestionKey);
					}
				}
		  }
		: null;

	get sectionCount() {
		return this.forms.reduce((p, c) => (p += c.ConsentFormQuestions.length), 0);
	}

	get currentSection() {
		return this.currentForm + 1 * this.currentQuestion + 1;
	}

	get localizeLangKey() {
		const lang = window.localStorage.getItem('ljs-lang');
		return lang ? kioskModule.langLookup(lang) : 1;
	}

	created() {
		this.isLoading = true;
		kioskModule.updateLastPage('WaiverForms');
		apiService
			.get('/getEntityConsentForms', {
				languageKey: this.localizeLangKey,
				isGuardian: kioskModule.minorParticipants.length > 0,
				clientLocationKey: kioskModule.clientLocationKey,
			})
			.then((res: ConsentFormEntity[]) => {
				console.log('[ConsentFormEntity]', res);
				if (res) {
					this.forms = res;
				}
			})
			.finally(() => {
				this.isLoading = false;
				if (process.env.VUE_APP_DEV_MODE) this.skipWaivers!();
			});
	}

	onWaiverAgree(questionKey: number) {
		kioskModule.updateAgreements({ formKey: this.forms[this.currentForm].ConsentFormKey, questionKey });

		if (this.forms[this.currentForm].ConsentFormQuestions.length > this.currentQuestion + 1) this.currentQuestion++;
		else if (this.forms.length > this.currentForm + 1) {
			this.currentForm++;
			this.currentQuestion = 0;
		}

		document.getElementById('waiverTop')?.scrollIntoView();
	}

	async onSubmitForm(args: { signature: string; questionKey: number }) {
		this.isLoading = true;
		kioskModule.updateAgreements({ formKey: this.forms[this.currentForm].ConsentFormKey, questionKey: args.questionKey });
		kioskModule.updateSignature(args.signature);
		kioskModule.submitWaiver().then((res) => {
			if (res) this.$router.push({ name: 'Finished', query: { ...this.$route.query } });
		});
	}

	goBack() {
		if (this.currentQuestion - 1 >= 0) this.currentQuestion--;
		else if (this.currentForm - 1 >= 0) {
			this.currentForm--;
			this.currentQuestion = 0;
		} else {
			let route: string;
			if (this.$route.query.unassigned) {
				route = kioskModule?.consenter?.consentMinors?.length ? 'MinorsCheckin' : 'AdultCheckin';
			} else {
				route = kioskModule.order!.CheckInOrderEntities.length > 1 ? 'ActivitySelection' : kioskModule.consenter!.consentMinors?.length ? 'MinorsCheckin' : 'AdultCheckin';
			}
			this.$router.push({ name: route, query: { ...this.$route.query } });
		}
	}
}
