
import { MinorParticipant } from '@/common/types/MinorParticipant.type';
import { isBefore, sub } from 'date-fns';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TextInput from '@/modules/consent/components/form/text-input.component.vue';
import DateInput from '@/modules/consent/components/form/date-input.component.vue';
import CustomFieldInput from '@/modules/consent/components/form/custom-field-input.component.vue';
import { CustomField } from '@/common/types/client.type';
import isSameDay from 'date-fns/isSameDay';
import { kioskModule } from '@/store/kiosk.vuex-module';
import SkiRentalInput from '@/modules/consent/components/form/ski-rental-input.component.vue';

@Options({
	name: 'MinorInfoForm',
	components: { TextInput, DateInput, CustomFieldInput, SkiRentalInput },
	emits: ['removeMinor'],
})
export default class MinorInfoForm extends Vue {
	@Prop() minor!: MinorParticipant;
	@Prop() index!: number;
	@Prop() minAge!: number;
	@Prop() activityDate!: Date | null;

	customFields: CustomField[] = [];

	invalidFirstName = false;
	invalidLastName = false;
	invalidBirthDate = false;

	skiRentalErrors = {
		exp: false,
		height: false,
		weight: false,
		shoes: false,
	};

	get enteredBirthDate(): boolean {
		return this.minor.dateOfBirth.day != null && this.minor.dateOfBirth.month != null && this.minor.dateOfBirth.year != null;
	}

	get maxAge() {
		return kioskModule.adultAge ? kioskModule.adultAge + 1 : 19;
	}

	get skiRentalFields() {
		return kioskModule.skiRentalFieldResponses;
	}

	get collectSkiRentalInfo() {
		return kioskModule.collectSkiRentalInfo;
	}

	onDateEntered(date: { day: number; month: number; year: number }) {
		this.minor.dateOfBirth = date;
		this.invalidBirthDate = !this.isValidBirthDate();
	}

	isValidBirthDate(): boolean {
		if (this.activityDate) {
			if (!this.enteredBirthDate) return true;
			const dob = new Date(this.minor.dateOfBirth.year!, this.minor.dateOfBirth.month!, this.minor.dateOfBirth.day!);
			const minDate = sub(new Date(this.activityDate ?? new Date()), { years: this.minAge });
			return isBefore(dob, minDate) || isSameDay(minDate, dob);
		}
		return true;
	}

	get minorDobErrorMsg() {
		return `Minor must be at least ${this.minAge} years old on date of activity`;
	}

	validate() {
		this.invalidFirstName = !this.minor.firstName.length;
		this.invalidLastName = !this.minor.lastName.length;
		this.invalidBirthDate = this.enteredBirthDate ? !this.isValidBirthDate() : true;

		// validate custom fields
		if (this.minor.customFields) {
			this.customFields = [];
			this.minor.customFields?.forEach((cf, i: number) => {
				const v = (this.$refs[`cf${i}`] as CustomFieldInput).validate();
				if (v) this.customFields.push(v);
			});
		}

		if (this.collectSkiRentalInfo) {
			this.skiRentalErrors.exp = this.minor.skiExperienceKey == undefined;
			this.skiRentalErrors.height = this.minor.skiHeightKey == undefined;
			this.skiRentalErrors.weight = this.minor.skiWeightKey == undefined;
			this.skiRentalErrors.shoes = this.minor.skiShoeSizeKey == undefined;
		}
		return !this.invalidFirstName && !this.invalidLastName && !this.invalidBirthDate && (this.minor.customFields ? this.minor.customFields.length == this.customFields!.length : true);
	}
}
