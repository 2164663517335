
import { AdultParticipant } from '@/common/types/AdultParticipant.type';
import { MinorParticipant } from '@/common/types/MinorParticipant.type';
import { kioskModule } from '@/store/kiosk.vuex-module';
import differenceInYears from 'date-fns/fp/differenceInYears/index.js';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ActivityParticipant',
	emits: ['onToggle'],
})
export default class ActivityParticipant extends Vue {
	@Prop() participant!: AdultParticipant | MinorParticipant;
	@Prop({ default: false }) checked!: boolean;
	@Prop({ default: 0 }) minAge!: number;

	editState!: boolean;

	created() {
		this.editState = kioskModule.isEditing && this.checked;
	}

	onToggle() {
		if (!this.editState) this.$emit('onToggle');
	}

	get ageRestricted() {
		return this.age < this.minAge;
	}

	get name(): string {
		return `${this.participant.firstName.charAt(0).toUpperCase() + this.participant.firstName.slice(1)} ${this.participant.lastName.charAt(0).toUpperCase() + this.participant.lastName.slice(1)}`;
	}

	get age() {
		return differenceInYears(new Date(this.participant.dateOfBirth.year!, this.participant.dateOfBirth.month!, this.participant.dateOfBirth.day!), new Date());
	}
}
