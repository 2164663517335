
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'YesNoInput',
	emits: ['onStatusChange'],
})
export default class YesNoInput extends Vue {
	@Prop({ default: '' }) label!: string;
	@Prop({
		default: {
			label: '',
			selected: false,
		},
	})
	yesBtn!: {
		label?: string;
		selected?: boolean;
	};

	@Prop({
		default: {
			label: '',
		},
	})
	noBtn!: {
		label?: string;
	};
	@Prop() showError!: boolean;
	@Prop({ default: 0 }) uniformPadding!: number;
	currentStatus = false;

	created(): void {
		this.currentStatus = this.yesBtn.selected ? true : false;
	}

	mounted() {
		// override default padding to custom uniform padding
		if (this.uniformPadding)
			Array.from(document.getElementsByClassName('form-rd-btn')).forEach((e) => {
				(e as HTMLElement).style.padding = `${this.uniformPadding}px`;
			});
	}

	get status(): boolean {
		return this.currentStatus;
	}

	set status(v: boolean) {
		this.currentStatus = v;
		this.$emit('onStatusChange', this.currentStatus);
	}
}
