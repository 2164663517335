
import getDaysInMonth from 'date-fns/fp/getDaysInMonth/index';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'DateInput',
	components: {},
	emits: ['onDateEntered', 'onBlur'],
})
export default class DateInput extends Vue {
	@Prop() label!: string;
	@Prop() readonly day!: number;
	@Prop() readonly month!: number;
	@Prop() readonly year!: number;
	@Prop() showError!: boolean;
	@Prop({ default: 'A valid date is required' }) errorMsg!: string;
	@Prop({ default: 100 }) numYears!: number;
	@Prop({ default: false }) altStyle!: boolean;
	@Prop({ default: '' }) id!: string;

	inpDay!: number;
	inpMonth!: number;
	inpYear!: number;

	created() {
		this.inpDay = this.day;
		this.inpMonth = this.month;
		this.inpYear = this.year;
	}

	onBlur() {
		if (this.inpDay != null && this.inpMonth != null && this.inpYear != null) {
			this.$emit('onBlur');
		}
	}

	onDateChange() {
		if (this.inpDay != null && this.inpMonth != null && this.inpYear != null) {
			// check if day exists in month
			if (getDaysInMonth(new Date(this.inpYear, this.inpMonth)) >= this.inpDay) this.$emit('onDateEntered', { day: this.inpDay, month: this.inpMonth, year: this.inpYear });
		}
	}

	get months() {
		return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
	}

	get days() {
		return Array.from(Array(31), (_, i) => i + 1);
	}

	get years() {
		const currYear = new Date().getFullYear();
		return Array.from(Array(this.numYears), (_, i) => currYear - i);
	}
}
