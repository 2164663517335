
import { AdultParticipant } from '@/common/types/AdultParticipant.type';
import { MinorParticipant } from '@/common/types/MinorParticipant.type';
import { OrderActivity } from '@/common/types/OrderActivity.type';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ActivityParticipant from '@/modules/consent/components/activity-participant.component.vue';
import { differenceInYears } from 'date-fns';
import isPast from 'date-fns/isPast/index';

@Options({
	name: 'ActivityCard',
	components: { ActivityParticipant },
	emits: ['participantAdded'],
})
export default class ActivityCard extends Vue {
	@Prop() activity!: OrderActivity;

	// returns a list of consenter and minors
	get participants() {
		const pats: (AdultParticipant | MinorParticipant)[] = [];
		if (kioskModule.consenter?.isParticipating) pats.push(kioskModule.consenter);
		kioskModule.minorParticipants.forEach((mp) => pats.push(mp));

		return pats.sort((a, b) => {
			const diffA = differenceInYears(new Date(a.dateOfBirth.year!, a.dateOfBirth.month!, a.dateOfBirth.day!), new Date());
			const diffB = differenceInYears(new Date(b.dateOfBirth.year!, b.dateOfBirth.month!, b.dateOfBirth.day!), new Date());
			return diffA > diffB ? 1 : -1;
		});
	}

	get disableCard() {
		return isPast(new Date(`${this.activity.AppointmentDate} 23:59`));
	}

	get activityParticipants() {
		return kioskModule.activityParticipants[this.activity.OrderEntityTimeKey] ?? [];
	}

	toggleParticipant(id: string) {
		kioskModule.toggleActivityParticipant({ activityId: this.activity.OrderEntityTimeKey, participantId: id });
	}
}
