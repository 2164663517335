import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "name", "value", "id"]
const _hoisted_3 = { class: "invalid-input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-input flex-col", { invalid: _ctx.showError }])
  }, [
    _createElementVNode("label", { for: _ctx.elementName }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("input", {
      type: _ctx.type,
      name: _ctx.elementName,
      class: _normalizeClass(["si-input", { 'si-alt': _ctx.altStyle }]),
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onBlur', _ctx.modelValue == null ? true : !_ctx.modelValue.replaceAll(' ', '').length))),
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onChange', !_ctx.modelValue.length))),
      id: _ctx.id
    }, null, 42, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1)
  ], 2))
}