import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "a-title flex-row" }
const _hoisted_2 = {
  class: "bold",
  notranslate: ""
}
const _hoisted_3 = { class: "a-date" }
const _hoisted_4 = { class: "p-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_activity_participant = _resolveComponent("activity-participant")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["activity si-card flex-col", { 'card-disabled': _ctx.disableCard }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.activity.EntityHierarchyName), 1)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.activity.AppointmentDate) + " " + _toDisplayString(_ctx.activity.Time), 1),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participants, (p) => {
        return (_openBlock(), _createBlock(_component_activity_participant, {
          key: p.guid,
          participant: p,
          checked: _ctx.activityParticipants.includes(p.guid),
          minAge: _ctx.activity.ChildMinAge,
          onOnToggle: ($event: any) => (_ctx.toggleParticipant(p.guid))
        }, null, 8, ["participant", "checked", "minAge", "onOnToggle"]))
      }), 128))
    ])
  ], 2))
}