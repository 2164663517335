
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'SkiRentalInput',
	components: {},
	emits: ['onBlur', 'onChange', 'update:modelValue'],
})
export default class SkiRentalInput extends Vue {
	@Prop() label!: string;
	@Prop() responses!: {
		key: number;
		desc: string;
	}[];
	@Prop({ default: -1 }) modelValue!: number | null;
	@Prop({ default: false }) altStyle!: boolean;
	@Prop({ default: 'This field is required' }) errorMsg!: string;
	@Prop() showError!: boolean;
	@Prop({ default: '' }) helpText!: string;

	get id() {
		return `skiField-${this.label}`;
	}
}
