import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "si-sel-wrapper" }
const _hoisted_3 = ["value", "id"]
const _hoisted_4 = {
  value: "-1",
  disabled: "true",
  selected: ""
}
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "invalid-input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-input flex-col", { invalid: _ctx.showError }])
  }, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("select", {
        class: _normalizeClass(["si-input", { 'si-alt': _ctx.altStyle }]),
        value: _ctx.modelValue,
        id: _ctx.id,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onBlur', _ctx.modelValue == undefined)))
      }, [
        _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.helpText), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (res, idx) => {
          return (_openBlock(), _createElementBlock("option", {
            key: idx,
            value: res.key
          }, _toDisplayString(res.desc), 9, _hoisted_5))
        }), 128))
      ], 42, _hoisted_3)
    ]),
    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.errorMsg), 1)
  ], 2))
}