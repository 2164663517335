
import { apiService } from '@/services/api.service';
import { Options, Vue } from 'vue-class-component';
import DateInput from '@/modules/consent/components/form/date-input.component.vue';
import { OnClickOutside } from '@vueuse/components';
import { AdultParticipant } from '@/common/types/AdultParticipant.type';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { format } from 'date-fns';
import { UserConsentOrderTimes } from '@/common/types/UserConsentOrderTimes.type';

type OrderConsent = {
	ConsentKey: number;
	FirstName: string;
	LastName: string;
	IsParticipating: boolean;
	UserConsentOrderEntityTimeKey: number;
};

@Options({
	name: 'CompletedWaiversView',
	components: { DateInput, OnClickOutside },
})
export default class CompletedWaiversView extends Vue {
	isLoading = true;
	showBackBtn = true;

	consents: { firstName: string; lastName: string; consentKey: number }[] = [];

	showModal = false;
	selectedConsent = 0;
	confirmationDate = { day: null, month: null, year: null };
	// confirmationDate = { day: 31, month: 6, year: 1987 };
	invalidDate = false;
	isConfirming = false;

	get canEdit() {
		return kioskModule.order ? kioskModule.order.CheckInOrderEntities.length > 1 : false;
	}

	created() {
		kioskModule.updateLastPage('Completed');
		apiService
			.externalGet(`${(window as any).config.ApiUrl}/v1/checkin/getConsentsByOrder`, {
				excludeMinors: true,
				orderKey: this.$route.params.orderKey,
			})
			.then((res: OrderConsent[]) => {
				console.log(res);
				if (res && Array.isArray(res))
					this.consents = res.map((oc) => {
						return {
							firstName: oc.FirstName,
							lastName: oc.LastName,
							consentKey: oc.ConsentKey,
						};
					});
			})
			.finally(() => (this.isLoading = false));
	}

	closeModal() {
		if (!this.isConfirming) {
			this.confirmationDate = { day: null, month: null, year: null };
			this.showModal = false;
			this.invalidDate = false;
		}
	}

	confirmEdit(i: number) {
		if (!this.canEdit) return;

		this.selectedConsent = i;
		this.showModal = true;
	}

	validateBirthday() {
		this.isConfirming = true;
		apiService
			.get(`${(window as any).config.ApiUrl}/v1/checkin/getUserConsent`, {
				consentkey: this.consents[this.selectedConsent].consentKey,
				dateofbirth: `${(this.confirmationDate.month ?? 0) + 1}/${this.confirmationDate.day}/${this.confirmationDate.year}`,
			})
			.then((c) => {
				if (c) {
					console.log(c);

					const session = kioskModule.session;
					session.isEditing = true;

					// map consenter
					const consenter: AdultParticipant = {
						consentKey: c.ConsentKey,
						consentMinors: c.ConsentMinors.map((minor: any) => {
							return {
								firstName: minor.FirstName,
								lastName: minor.LastName,
								email: '',
								dateOfBirth: {
									day: new Date(minor.DateOfBirth).getDate(),
									month: new Date(minor.DateOfBirth).getMonth(),
									year: new Date(minor.DateOfBirth).getFullYear(),
									formatted: format(new Date(minor.DateOfBirth), 'M-d-yyyy'),
								},
								guid: minor.Guid,
								canDelete: minor.canDelete,
								customFields: [],
								consentMinorKey: minor.ConsentMinorKey,
							};
						}),
						customFields: [],
						firstName: c.FirstName,
						lastName: c.LastName,
						isParticipating: c.IsParticipating,
						email: c.Email,
						emailOptIn: c.EmailOptIn,
						zipCode: c.ZipCode,
						city: c.City,
						state: c.State,
						countryKey: c.CountryKey,
						signatureBase64: c.SignatureBase64,
						dateOfBirth: {
							day: new Date(c.DateOfBirth).getDate(),
							month: new Date(c.DateOfBirth).getMonth(),
							year: new Date(c.DateOfBirth).getFullYear(),
							formatted: format(new Date(c.DateOfBirth), 'yyyy/MM/dd'),
						},
						languageKey: c.LanguageKey,
						guid: c.Guid,
					};
					session.consenter = consenter;

					// map participating activities
					const assignedActivities = new Set<string>();

					c.UserConsentOrderEntityTimes.forEach((time: UserConsentOrderTimes) => {
						const timeParticipants: string[] = [];

						if (time.Consentees.Consentor.IsParticipating) timeParticipants.push(time.Consentees.Consentor.Guid); // push consentor

						timeParticipants.push(...time.Consentees.ConsentMinors.map((m) => m.Guid)); // push minors

						if (timeParticipants.length) {
							session.activityParticipants[time.OrderEntityTimeKey] = timeParticipants;
							assignedActivities.add(time.OrderEntityTimeKey);
						}
					});
					// c.UserConsentOrderEntityTimes.forEach((time: UserConsentOrderTimes) => {
					// 	if (time.Consentees.Consentor.IsParticipating || time.Consentees.ConsentMinors.length) {
					// 		session.activityParticipants[time.OrderEntityTimeKey] = [time.Consentees.Consentor.Guid];
					// 		assignedActivities.add(time.OrderEntityTimeKey);
					// 	}
					// });

					session.editActivities = Array.from(assignedActivities);

					// map consent form agreements
					// session.formAgreements = c.ConsentForms.map((form: ConsentFormEntity) => {
					// 	return {
					// 		formId: form.ConsentFormKey,
					// 		questions: form.ConsentFormQuestions.map((q) => q.ConsentFormQuestionKey),
					// 	};
					// });

					session.signature = c.SignatureBase64;

					kioskModule.saveSession(session);
					console.log(session);
					this.$router.push({ name: 'ActivitySelection' });
				} else this.invalidDate = true;
			})
			.catch((e) => {
				this.invalidDate = true;
				console.log(e);
			})
			.finally(() => {
				this.isConfirming = false;
			});
	}

	goBack() {
		this.$router.push({ name: 'AdultCheckin' });
	}
}
